$white: #ffffff;
$black: #000000;

$light: #f6f6f6;
$dark: #333333;

$canfur_primary: #ff6f00;

$canfur_primary_dark: #4a148c;
$canfur_primary_dark_rgb: 74, 20, 140;

[data-bs-theme='dark'] {
  --bs-canfur-primary-rgb: 74, 20, 140;
  --bs-canfur-primary: #4a148c;
}
